import { NextSeo } from "next-seo";

export interface SEOProps {
  title: string;
  description: string;
  image?: string;
}

const DEFAULT_OG_IMAGE =
  "https://res.cloudinary.com/dl7p14ddo/image/upload/v1728683958/vital/og-image-shadow_dlyf8i.jpg";

const SEO = ({ title, description, image }: SEOProps) => {
  const ogImage = image ?? DEFAULT_OG_IMAGE;

  return (
    <NextSeo
      title={title + " | Vital"}
      description={description}
      twitter={{
        cardType: "summary_large_image",
        handle: "@VitalScoreAI",
      }}
      openGraph={{
        url: "https://www.vitalscore.ai",
        title: title + " | Vital",
        description: description,
        locale: "en_US",
        images: [
          {
            url: ogImage,
            width: 1200,
            height: 630,
            alt: title,
            type: "image/jpg",
          },
        ],
      }}
      additionalLinkTags={[
        {
          rel: "icon",
          href: "https://www.vitalscore.ai/favicon.ico",
        },
      ]}
    />
  );
};

export default SEO;
