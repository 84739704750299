import React, { useEffect, useState } from "react";
import {
  faCheck,
  faClose,
  faEnvelope,
  faSpinner,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import * as Dialog from "@radix-ui/react-dialog";
import classnames from "classnames";

import { api } from "~/utils/api";
import Button from "./Button";
import Input from "./Input";

function WaitlistDialog({
  children,
}: {
  children: React.ReactNode;
  classnames?: string;
}) {
  const [isOpen, setIsOpen] = useState(false); // Controlled open state
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const { mutate, isLoading } = api.waitlist.join.useMutation({
    onSuccess: () => {
      setSubmitted(true);
      setError(null);
    },
    onError: (error) => {
      if (error.data?.zodError?.fieldErrors) {
        const fieldErrors = error.data.zodError.fieldErrors;
        const firstErrorMessage = Object.values(fieldErrors)[0]?.[0];
        setError(firstErrorMessage ?? "An error occurred");
      } else {
        setError(error.message);
      }
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Simple validation to ensure both fields are not empty
    if (name.trim() === "" || email.trim() === "") {
      setError("Both name and email are required.");
      return;
    }
    setError(null);
    mutate({ email, name });
  };

  const handleClose = () => {
    setIsOpen(false); // Close the dialog
    setSubmitted(false);
    setName("");
    setEmail("");
    setError(null);
  };

  const handleOpenChange = (open: boolean) => {
    if (open) {
      setIsOpen(true);
    } else {
      // Prevent closing the dialog from outside interactions (like 1Password widget)
      // Only allow closing via the close button
      // Do not update `isOpen` here
    }
  };

  if (!isMounted) {
    return <>{children}</>;
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleOpenChange}>
      <Dialog.Trigger asChild>
        <button onClick={() => setIsOpen(true)}>{children}</button>
      </Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay
          onClick={() => setIsOpen(false)}
          className="fixed inset-0 z-50 h-screen w-screen animate-fade bg-black/10 backdrop-blur-sm transition-all duration-150"
        />
        <Dialog.Content className="fixed left-1/2 top-1/2 z-50 flex w-[90vw] max-w-md -translate-x-1/2 -translate-y-1/2 flex-col gap-4 rounded-2xl bg-base-100 p-8">
          <Dialog.Title
            className={classnames(
              "pr-12 text-2xl font-semibold",
              submitted ? "hidden" : "",
            )}
          >
            Join the waitlist to get{" "}
            <span className="font-semibold underline">early access</span>
          </Dialog.Title>
          <Dialog.Title
            className={classnames(
              "pr-12 text-2xl font-semibold",
              submitted ? "" : "hidden",
            )}
          >
            Success!
          </Dialog.Title>
          <Dialog.Description />
          {submitted ? (
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1 text-base font-normal">
                <p className="text-base font-medium">
                  You are on the waitlist.
                </p>
                <p className="text-base font-normal text-base-content/40">
                  You will receive a welcome email shortly. (If you do not see
                  it after a few minutes, please check your junk mail).
                </p>
              </div>
              <div className="flex justify-end">
                <Dialog.Close asChild>
                  <Button onClick={handleClose} variant="solid">
                    Close
                  </Button>
                </Dialog.Close>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="flex flex-col gap-2">
              <fieldset className="flex flex-col gap-2">
                <Input
                  id="name"
                  type="text"
                  placeholder="Name"
                  leftIcon={faUser}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </fieldset>
              <fieldset className="flex flex-col gap-2">
                <Input
                  id="email"
                  type="email"
                  placeholder="Email"
                  leftIcon={faEnvelope}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                {error && (
                  <p className="text-right text-xs text-red-500">{error}</p>
                )}
              </fieldset>
              <div className="flex justify-end">
                <Button
                  type="submit"
                  variant="solid"
                  rightIcon={isLoading ? faSpinner : faCheck}
                  isLoading={isLoading}
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </Button>
              </div>
            </form>
          )}
          <Dialog.Close asChild>
            <Button
              variant={"ghost"}
              rightIcon={faClose}
              className="absolute right-2 top-2 !pl-1.5"
              aria-label="Close"
              onClick={handleClose} // Ensure handleClose is called
            />
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default WaitlistDialog;
