import classnames from "classnames";

import { useTheme } from "~/components/theme/ThemeProvider";
import Footer from "./Footer";
import Header from "./Header";
import SEO from "./SEO";
import type { SEOProps } from "./SEO";

interface Props {
  seo: SEOProps;
  children: React.ReactNode;
}

const PageLayout = ({ seo, children }: Props) => {
  const { theme } = useTheme();

  return (
    <>
      <SEO {...seo} />
      <>
        <main
          className={classnames(
            "flex min-h-screen flex-col items-center justify-start overflow-x-hidden bg-base-100 text-base-content",
            theme === "dark" ? "dark" : "",
          )}
        >
          <Header />
          {children}
          <Footer />
        </main>
      </>
    </>
  );
};

export default PageLayout;
