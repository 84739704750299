import { useState } from "react";
import type { HTMLInputTypeAttribute } from "react";
import type { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props extends React.HTMLAttributes<HTMLInputElement> {
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  leftIcon?: IconDefinition;
  rightIcon?: IconDefinition;
  className?: string;
  required?: boolean;
}

const Input = ({
  type,
  placeholder,
  value,
  onChange,
  leftIcon,
  rightIcon,
  className,
  required,
}: Props) => {
  const [inFocus, setInFocus] = useState(false);

  return (
    <div
      className={`bg-base-content-100 flex h-[40px] w-full flex-row rounded-md border border-base-300 ${
        inFocus ? "border-base-content/50" : ""
      } ${className}`}
    >
      {leftIcon && (
        <div className="flex aspect-square h-[40px] items-center justify-center">
          <FontAwesomeIcon
            icon={leftIcon}
            className="h-4 w-4 text-base-content/20"
          />
        </div>
      )}
      <input
        type={type}
        required={required}
        placeholder={placeholder}
        className={`h-full w-full bg-transparent px-3 text-base font-normal leading-none focus:outline-none
        ${leftIcon && "pl-0"} ${rightIcon && "pr-0"}`}
        value={value}
        onChange={onChange}
        onFocus={() => setInFocus(true)}
        onBlur={() => setInFocus(false)}
      />
      {rightIcon && (
        <div className="aspect-square h-full items-center justify-center">
          <FontAwesomeIcon
            icon={rightIcon}
            className="h-4 w-4 text-base-content/20"
          />
        </div>
      )}
    </div>
  );
};

export default Input;
