import type { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

export const variants = {
  solid: "bg-base-content hover:shadow-lg text-base-100",
  primary: "bg-grapefruit-500 text-white hover:shadow-lg",
  primarySubtle: "text-grapefruit-500 bg-grapefruit-500/10",
  outline:
    "bg-transparent border-[1px] border-base-content/50 hover:bg-base-content/5 hover:border-base-content text-base-content",
  ghost: "text-base-content bg-transparent hover:bg-base-content/5",
  subtle: "text-base-content bg-base-200 hover:bg-base-300",
  green: "bg-green text-green-content hover:bg-green-focus",
  blue: "bg-blue text-blue-content hover:bg-blue-focus",
} as const;

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  leftIcon?: IconDefinition;
  rightIcon?: IconDefinition;
  className?: string;
  variant: keyof typeof variants;
  children?: React.ReactNode;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
}

const Button = ({
  children,
  isLoading,
  leftIcon,
  rightIcon,
  className,
  type,
  ...props
}: Props) => {
  return (
    <button
      disabled={isLoading ?? props.disabled}
      className={classnames(
        "flex h-fit w-fit flex-row items-center justify-center gap-2.5 rounded-md px-4 py-3 align-baseline text-base font-semibold transition-all duration-200 focus:outline-none disabled:opacity-70",
        variants[props.variant ?? "solid"],
        className && className,
      )}
      type={type}
      {...props}
    >
      {leftIcon && <FontAwesomeIcon icon={leftIcon} className="h-3.5 w-3.5" />}
      <span className="font-medium leading-none tracking-tight">
        {children}
      </span>
      {rightIcon && (
        <FontAwesomeIcon icon={rightIcon} className="h-3.5 w-3.5" />
      )}
    </button>
  );
};

export default Button;
